import Avatar from '../globals/Avatar'
import Dropdown, { type IDropdownOption } from '../globals/Dropdown'
import styles from './NavBar.module.scss'
import Notifications from '~/components/layout/Notifications'
import { useStore } from '~/stores'
import { useAcl, useUser } from '~/hooks/user'
import ActiveTenant from '~/components/layout/navBar/ActiveTenant'
import { globalApi } from '~/services/APIService'
import clsx from 'clsx'

export default function NavBar() {
  const store = useStore()
  const { user } = useUser()
  const { isManager } = useAcl()

  const logout = async () => {
    location.href = globalApi.getUrl('logout')
  }

  const accountOptions: IDropdownOption[] = []

  if (user?.canSwitchToParticipantView) {
    accountOptions.unshift({
      label: `Switch to ${isManager ? 'user' : 'admin'}`,
      link: '/login/switch',
      icon: 'switch-horizontal-01'
    })
  }

  accountOptions.push({
    label: 'Logout',
    action: logout,
    icon: 'log-out-01'
  })

  return (
    <div className={styles.navbar}>
      <div className={clsx(styles.inner, 'container flex items-center px-24')}>
        <button type="button" className={styles.menuToggle} onClick={() => store.toggleSidebar()}>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
          <span className={styles.line}></span>
        </button>

        <ActiveTenant />

        <div className="flex-grow"></div>
        <div className="flex-auto flex items-center">
          {/*<Button icon="search" size="sm" className="color-grey-500"></Button>*/}
          <Notifications />
          {user && (
            <Dropdown
              activator={({ toggle }) => (
                <button className={styles.user} onClick={toggle}>
                  <Avatar user={user || undefined} />
                </button>
              )}
              options={accountOptions}
              className="ml-10"
            />
          )}
        </div>
      </div>
    </div>
  )
}
