import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type TenantStoreType = {
  userTenantUid?: string
  tenantUid?: string
  setTenantUid: (tenantUid: string) => void
  clearTenantUid: () => void
  setUserTenantUid: (tenantUid: string) => void
  clearUserTenantUid: () => void
}

export const useTenantStore = create<TenantStoreType>()(
  persist(
    (set) => ({
      userTenantUid: undefined,
      tenantUid: undefined,
      setTenantUid: (tenantUid: string) => set({ tenantUid }),
      clearTenantUid: () => set({ tenantUid: undefined }),
      setUserTenantUid: (tenantUid: string) => set({ userTenantUid: tenantUid }),
      clearUserTenantUid: () => set({ userTenantUid: undefined })
    }),
    {
      name: 'current-tenant'
    }
  )
)
