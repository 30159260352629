import BaseModel from '~/models/BaseModel'

export default class Notification extends BaseModel {
  declare id: number
  declare type: string
  declare link?: string
  declare message: string
  declare color: string
  declare readAt: string
  declare createdAt: string
}
