import { type Placement } from '@popperjs/core'
import { type ReactNode, useEffect, useState } from 'react'
import Popper from '~/components/utils/Popper'
import eventBus from '~/services/EventBus'
import clsx from 'clsx'
import styles from './Dropdown.module.scss'
import Pressable from './Pressable'
import TooltipWrap from '~/components/globals/TooltipWrap'
import { useAcl } from '~/hooks/user'
import { type Permission } from '~/models/User'
import SvgIcon from '~/components/common/SvgIcon'
import { useRouter } from 'next/router'

export interface IDropdownOption {
  label: string | ReactNode
  link?: string
  color?: string
  selected?: boolean
  disabled?: boolean
  permissions?: Permission
  tooltip?: string
  icon?: string
  action?: () => void
}

export type Activator = (props: { isToggled: boolean; toggle: () => void }) => JSX.Element

export type Props = {
  className?: string
  plain?: boolean
  size?: 'lg' | 'sm' | 'xs'
  placement?: Placement
  children?: ReactNode
  options?: IDropdownOption[]
  activator: Activator
  offset?: number[]
}

export default function Dropdown(props: Props) {
  const router = useRouter()
  const classNames = [styles.dropdown]
  props.className && classNames.push(props.className)
  props.plain && classNames.push('is-plain')
  props.size && classNames.push('size-' + props.size)

  const acl = useAcl()

  const [isToggled, setIsToggled] = useState(false)
  isToggled && classNames.push('is-toggled')

  const toggle = () => {
    setIsToggled(!isToggled)
  }

  const close = () => {
    setIsToggled(false)
  }

  const select = async (option: IDropdownOption) => {
    if (option.disabled) {
      return
    }

    if (option.link) {
      await router.push(option.link)
    }

    option.action?.()

    close()
  }

  useEffect(() => {
    const tryClose = () => setIsToggled(false)
    eventBus.on('dropdown.close', tryClose)
    return () => {
      eventBus.off('dropdown.close', tryClose)
    }
  }, [])

  const availableOptions = props.options?.reduce((options, item) => {
    let canInclude = true
    if (item.permissions && !acl.can(item.permissions as Permission)) {
      canInclude = false
    }
    if (canInclude) {
      options.push(item)
    }
    return options
  }, [] as IDropdownOption[]) as IDropdownOption[]

  const renderOptions = (item: IDropdownOption, i: number) => {
    const content = (
      <div key={i}>
        <Pressable
          className={clsx(styles.option, { 'is-disabled': item.disabled })}
          onClick={() => select(item)}
        >
          {item.icon ? <SvgIcon className="pr-8" icon={item.icon} height={16} /> : null}
          {item.label}
        </Pressable>
      </div>
    )

    return item.tooltip ? (
      <TooltipWrap key={i} title={item.tooltip} trigger={['hover', 'click']}>
        {content}
      </TooltipWrap>
    ) : (
      content
    )
  }

  if (availableOptions && availableOptions.length === 0) {
    return <></>
  }

  return (
    <Popper
      value={isToggled}
      onChange={setIsToggled}
      className={clsx(classNames)}
      placement={props.placement}
      offset={props.offset ?? [0, 5]}
      activator={props.activator({ isToggled, toggle })}
    >
      <div className={styles.holder}>
        {!!props.options?.length && (
          <div className={styles.options}>
            {availableOptions.map((item, i) => renderOptions(item, i))}
          </div>
        )}

        {props.children}
      </div>
    </Popper>
  )
}
