import { type ReactNode } from 'react'
import { useAcl } from '~/hooks/user'
import * as middlewares from '~/middlewares'
import { useUserStore } from '~/stores/user'
import { type MiddlewareContext, type MiddlewareResult } from '~/types/middlewares'
import Button from './globals/Button'
import ErrorState from './utils/ErrorState'
import { useRouter } from 'next/router'

type Props = {
  children?: ReactNode
  middleware?: string | string[]
}

export default function Middleware(props: Props) {
  const isLoggedIn = useUserStore((data) => data.isLoggedIn)
  const acl = useAcl()
  const router = useRouter()

  const context: MiddlewareContext = {
    isLoggedIn,
    acl,
    router
  }

  const list = Array.isArray(props.middleware)
    ? props.middleware
    : props.middleware
    ? [props.middleware]
    : []

  const result = list.reduce<MiddlewareResult | undefined>((result, item) => {
    if (result) {
      return result
    }

    const [name, args] = item.split(':')
    const params = (args || '').split(',').filter((item) => item)

    const response = middlewares[name as keyof typeof middlewares](context, ...params)

    return response && response !== true ? response : undefined
  }, undefined)

  if (result) {
    if (result.redirect) {
      window.location.href = result.redirect
    }

    if (result.error) {
      return (
        <ErrorState error={result.error}>
          <Button className="mt-32" to="/">
            Back to homepage
          </Button>
        </ErrorState>
      )
    }

    return <></>
  }

  return <>{props.children}</>
}
