import { type ReactNode } from 'react'
import Link from 'next/link'

type Props = {
  to: any
  className?: string
  children?: ReactNode
}

export default function ULink(props: Props) {
  return (
    <Link href={props.to}>
      <a className={props.className}>{props.children}</a>
    </Link>
  )
}
