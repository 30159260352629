let alertId = 1

type AlertType = 'error' | 'success' | 'warning' | 'info'
type ActionType = {
  label: string
  handler: () => void
}

export type AlertOptions = {
  id?: string
  type?: AlertType
  text: string | JSX.Element
  action?: ActionType
}

export default class Alert {
  declare id: string
  declare type: AlertType
  declare text: string | JSX.Element
  public timeout = 5000
  declare timer?: ReturnType<typeof setTimeout>
  declare action?: ActionType | null

  constructor(options: AlertOptions) {
    this.id = options.id || String(alertId++)
    this.type = options.type || 'success'
    this.text = options.text
    this.action = options.action || null
  }
}
