import BaseModel from '~/models/BaseModel'

export default class Tenant extends BaseModel {
  declare id: number
  declare uid: string
  declare name: string

  declare dryRunEmail?: string

  declare createdAt: string
  declare deletedAt?: string

  declare talentsCount?: number
  declare activeTalentsCount?: number

  public get displayName() {
    return `${this.name} (${this.uid})`
  }

  public get isActive(): boolean {
    return !this.deletedAt
  }

  public get currency() {
    return 'EUR'
  }
}
