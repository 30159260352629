import { useMount } from 'react-use'
import { lazyAsync } from '~/utils/helpers'
import { useMemo, useRef, useState } from 'react'
import Notification from '~/models/Notification'
import { globalApi } from '~/services/APIService'

export const useNotifications = () => {
  let updateTimer: ReturnType<typeof setTimeout> | null = null
  let isLoading = false
  const limit = 20
  const [list, setList] = useState<Notification[]>([])
  const [isToggled, setIsToggled] = useState(false)
  const [hasMore, setHasMore] = useState(false)

  const hasNew = useMemo(() => list.some((item) => !item.readAt), [list])

  const fetchData = async (loadMore = false) => {
    isLoading = true

    try {
      const offset = loadMore ? list.length : 0

      const results = await globalApi.$get('notifications', {
        params: {
          limit,
          offset
        }
      })

      const filter = (listed: Notification[]): Notification[] => {
        return results
          .filter(
            (item: any) => listed.findIndex((notification) => notification.id === item.id) === -1
          )
          .map((item: any) => new Notification(item))
      }

      if (loadMore) {
        setList((prevState) => [...prevState, ...filter(prevState)])
      } else {
        setList((prevState) => [...filter(prevState), ...prevState])
      }

      setHasMore(results.length === limit)
    } catch (err) {}

    isLoading = false
  }

  const fetch = lazyAsync(fetchData, 0, useRef(Math.random()).current)

  const toggle = () => {
    setIsToggled((prevState) => !prevState)
  }

  const onScroll = (target: HTMLElement) => {
    const isAtBottom = target.scrollHeight - target.clientHeight - target.scrollTop < 100

    if (isAtBottom && hasMore && !isLoading) {
      fetch(true)
    }
  }

  const scheduleUpdater = () => {
    updateTimer =
      updateTimer ||
      setTimeout(() => {
        updateTimer = null
        fetch(false)

        scheduleUpdater()
      }, 30000)
  }

  useMount(() => {
    fetch()
    scheduleUpdater()
  })

  return {
    isToggled,
    toggle,
    hasNew,
    list,
    onScroll
  }
}
