import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type ResponseType
} from 'axios'
import { Exception } from '~/exceptions'
import { APIException } from '~/exceptions/api'
import { downloadFile } from '~/utils/helpers'
import config from './ConfigService'

export class APIService {
  private client: AxiosInstance

  constructor(options: AxiosRequestConfig = {}) {
    this.client = axios.create(options)

    this.client.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        return Promise.reject(APIException.fromAxiosError(error))
      }
    )
  }

  public verifyError(err: unknown) {
    if (err instanceof APIException) {
      // continue
    } else if (err instanceof Exception) {
      // continue
    } else {
      throw err
    }
  }

  public handleError(err: unknown) {
    if (err instanceof APIException) {
      console.warn(`Failed to fetch data: ${err.statusCode}`)
    } else if (err instanceof Exception) {
      console.warn(`Failed to fetch data: ${err.message}`)
    } else {
      throw err
    }
  }

  public getUrl(endpoint?: string) {
    return [this.client.defaults.baseURL, endpoint].filter((item) => item).join('/')
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig) {
    const res = await this.client.get<T>(url, config)

    return res
  }

  public async $get<T = any>(url: string, config?: AxiosRequestConfig) {
    const res = await this.get<T>(url, config)

    return res.data
  }

  public async $post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.client.post<T>(url, data, config)

    return res.data
  }

  private dataToFormData(data?: any): FormData {
    const formData = new FormData()
    Object.entries(data ?? {}).forEach(([key, value]) => {
      if (value !== undefined) {
        formData.set(key, value as unknown as any)
      }
    })
    return formData
  }

  private configWithHeader(
    config: AxiosRequestConfig | undefined,
    key: string,
    value: string
  ): AxiosRequestConfig {
    return {
      ...(config ?? {}),
      headers: {
        ...(config?.headers ?? {}),
        [key]: value
      }
    }
  }

  public async $postFormData<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.client.post<T>(
      url,
      this.dataToFormData(data),
      this.configWithHeader(config, 'Content-Type', 'multipart/form-data')
    )

    return res.data
  }

  public async $put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.client.put<T>(url, data, config)

    return res.data
  }

  public async $putFormData<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.client.put<T>(
      url,
      this.dataToFormData(data),
      this.configWithHeader(config, 'Content-Type', 'multipart/form-data')
    )

    return res.data
  }

  public async $patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const res = await this.client.patch<T>(url, data, config)

    return res.data
  }

  public async $delete<T = any>(url: string, config?: AxiosRequestConfig) {
    const res = await this.client.delete<T>(url, config)

    return res.data
  }

  public async $download<T = any>(url: string, config?: AxiosRequestConfig, payload?: any) {
    const responseType: ResponseType = 'blob'
    const requestConfig = {
      ...(config ?? {}),
      responseType
    }

    const { headers, data } = payload
      ? await this.client.post<T>(url, payload, requestConfig)
      : await this.client.get<T>(url, requestConfig)

    const contentDisposition = headers['content-disposition']
    if (!contentDisposition) {
      return
    }

    let fileName = 'unknown'
    const fileNameMatch = contentDisposition.match(/filename="?(.+\.[a-zA-Z]+)"?/)
    if (fileNameMatch?.length === 2) {
      fileName = fileNameMatch[1]
    }

    downloadFile(data, fileName)
  }
}

export const globalApi = new APIService({
  baseURL: config.API_URL
})
