import { type Middleware } from '~/types/middlewares'

const authMiddleware: Middleware = (context) => {
  if (!context.isLoggedIn) {
    return {
      redirect: `${context.router.basePath}/login`
    }
  }
}

export default authMiddleware
