import BaseModel, { type WithURLs } from '~/models/BaseModel'
import { getInitials } from '~/utils/helpers'
import { type ITab } from '~/components/globals/TabBar'

export default class Project extends BaseModel implements WithURLs {
  declare id: number

  declare name: string
  declare isActive: boolean

  declare createdAt: string
  declare deletedAt: string | null
  declare updatedAt: string | null

  declare talentsCount?: number

  public get initials() {
    return getInitials('')
  }

  public get tab(): ITab {
    return {
      value: String(this.id),
      label: this.name
    }
  }

  public get listUrl() {
    return `/projects?projectId=${this.id}`
  }

  public get urlDetail() {
    return `/projects/${this.id}/profile`
  }

  public get urlEdit() {
    return `/projects/${this.id}/profile/edit`
  }

  public get urlLabel(): string {
    return 'test'
  }
}
