import NavBar from '~/components/layout/NavBar'
import SideBar from '~/components/layout/SideBar'

import styles from './dashboard.module.scss'
import { usePostHog } from '~/hooks/posthog'
import { useMount } from 'react-use'

export type Props = {
  children?: JSX.Element
}

export default function DashboardLayout({ children }: Props) {
  const posthog = usePostHog()

  useMount(posthog.mount)

  return (
    <>
      <SideBar />
      <main className={styles.main}>
        <NavBar />

        {children}
      </main>
    </>
  )
}
