import config from '~/services/ConfigService'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

export const useMaintenance = () => {
  const router = useRouter()
  const isInMaintenance = useMemo<boolean>(() => {
    return !!config.MAINTENANCE && ['true', '1'].includes(config.MAINTENANCE)
  }, [])

  const detect = async (page?: string) => {
    if (isInMaintenance) {
      await router.push('/maintenance')
    } else if (page) {
      await router.push(page)
    }
  }

  return {
    detect,
    isInMaintenance
  }
}
