import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import type Tenant from '~/models/Tenant'
import type Project from '~/models/Project'

export const useCompanyStore = create(
  combine(
    {
      tenant: {} as Tenant,
      projects: [] as Project[]
    },
    (set) => ({
      setTenant(tenant: Tenant) {
        set({ tenant })
      },
      setProjects(projects: Project[]) {
        set({ projects })
      }
    })
  )
)
