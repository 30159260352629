import Badge from '~/components/globals/Badge'
import { useTenant } from '~/hooks/tenant'
import { useMemo } from 'react'
import { useUser } from '~/hooks/user'

export default function ActiveTenant() {
  const { tenant } = useTenant()
  const { userTenantUid } = useUser()

  const isSwitched = useMemo<boolean>(
    () => !!tenant.uid && tenant.uid !== userTenantUid,
    [tenant.uid, userTenantUid]
  )

  return isSwitched ? (
    <Badge color="grey-100" textColor="grey-dark">
      <>
        {tenant.uid} : {tenant.name}
      </>
    </Badge>
  ) : null
}
