import Link from 'next/link'
import { type ReactNode } from 'react'

type Props = {
  children?: ReactNode
  className?: string
  to?: any
  onClick?: () => void
}

export default function Pressable(props: Props) {
  if (props.to) {
    return (
      <Link href={props.to}>
        <a className={props.className}>{props.children}</a>
      </Link>
    )
  }

  if (props.onClick) {
    return (
      <button type="button" className={props.className} onClick={props.onClick}>
        {props.children}
      </button>
    )
  }

  return <div className={props.className}>{props.children}</div>
}
