import BaseModel from '~/models/BaseModel'
import { getInitials } from '~/utils/helpers'

export const Permissions = [
  'manage_talents',
  'talents',
  'manage_projects',
  'projects',
  'settings'
] as const
export type Permission = (typeof Permissions)[number]

export const Roles = ['admin', 'user'] as const
export type Role = (typeof Roles)[number]

export default class User extends BaseModel {
  declare id: number

  declare uid: string
  declare name: string
  declare email: string

  declare permissions: Permission[]
  declare roles: Role[]

  declare activeRoles: Role[]

  declare tenant: {
    id: number
    uid: string
  }

  declare createdAt: string

  public get initials() {
    return getInitials(this.name)
  }

  public get lang(): string {
    return 'en'
  }

  public get canSwitchToParticipantView(): boolean {
    return this.roles.includes('admin')
  }
}
