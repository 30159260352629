import { useEffect, useState } from 'react'
import { usePostHog as usePostHogOrigin } from 'posthog-js/react'
import { useUser } from '~/hooks/user'

export const usePostHog = () => {
  const [isReady, setIsReady] = useState(false)
  const posthog = usePostHogOrigin()
  const { user, userTenantUid } = useUser()

  const mount = () => {
    if (!isReady) {
      posthog.init('phc_4IPIISGJd3Ov2KeMh84iuaRLWkTY7CQ9mIPwEuyFS0j', {
        api_host: 'https://eu.posthog.com'
      })

      setIsReady(true)
    }
  }

  useEffect(() => {
    if (isReady && userTenantUid && user) {
      posthog?.identify(String(user.id), {
        email: user.email
      })

      posthog?.group('company', userTenantUid)
    }
  }, [posthog, userTenantUid, user, isReady])

  return {
    mount
  }
}
