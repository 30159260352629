import { useMemo } from 'react'
import { APIService } from '~/services/APIService'
import config from '~/services/ConfigService'
import logger from '~/services/LoggerService'
import { useTenantStore } from '~/stores/tenant'
import { useCompanyStore } from '~/stores/company'
import TenantModel from '~/models/Tenant'
import Project from '~/models/Project'

export const useTenant = () => {
  const tenantStore = useTenantStore()
  const companyStore = useCompanyStore()

  const tenantUid = useMemo<string | undefined>(
    () => tenantStore.tenantUid ?? tenantStore.userTenantUid,
    [tenantStore.tenantUid, tenantStore.userTenantUid]
  )

  const tenantApi = useMemo<APIService>(
    () =>
      new APIService({
        baseURL: config.API_URL + '/' + tenantUid
      }),
    [tenantUid]
  )

  const fetchProjects = async () => {
    if (tenantUid) {
      const result = await tenantApi.$get(`projects`)
      if (Array.isArray(result)) {
        const projects = result.map((project: object) => new Project(project))

        companyStore.setProjects(projects)
      }
    }
  }

  const fetchTenantData = async () => {
    if (tenantUid) {
      const result = await tenantApi.$get('tenant')
      const data = new TenantModel(result)
      companyStore.setTenant(data)

      logger.debug('Tenant', data)
    }
  }

  const fetchTenant = async () => {
    try {
      await Promise.all([fetchTenantData(), fetchProjects()])
    } catch (e) {
      //
    }
  }

  return {
    tenant: companyStore.tenant,
    setTenant: companyStore.setTenant,
    changeTenant: tenantStore.setTenantUid,
    projects: companyStore.projects,
    tenantApi,
    tenantUid,
    fetchTenant,
    fetchProjects
  }
}

export type Tenant = ReturnType<typeof useTenant>
